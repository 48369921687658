import React, { useEffect } from 'react'

function FacilityManagement() {
  
  useEffect(() => {
    document.title = 'Facility Management | Drone Soft'
  }, []);

  return (
    <div>FacilityManagement</div>
  )
}

export default FacilityManagement
import React, { useState } from 'react'

function Contact() {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    console.log(message);
  }

  return (
    <section className='contact-us container mx-auto my-16 px-24'>
        <h3 className='text-center uppercase color-green text-lg mb-8'>Napište nám</h3>
        
        <form method='post' onSubmit={submitForm}>
            <div className='grid grid-cols-2 gap-x-12 gap-y-2'>
                <div>
                    <input type='text' name='name' placeholder='jméno' value={name} onChange={(e) => setName(e.target.value)} />  
                    <input type='email' name='email' placeholder='e-mail' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type='tel' name='phone' placeholder='telefon' value={phone} onChange={(e) => setPhone(e.target.value)} style={{ marginBottom: 0 }} />
                </div>
                <div>
                    <textarea name='message' placeholder='zpráva' rows='4' className='h-full' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
                <div>

                </div>
                <div>
                    <button type='submit'>Odeslat</button>
                </div>
            </div>
        </form>

    </section>
  )
}

export default Contact